<template>
    <breadcrumb btnText="返回列表" btnIcon="n-direction-left" @btn-click="$router.go(-1)"></breadcrumb>
    <div class="page-permission-edit">
        <permission-form title="编辑角色" type="role" :data="data"></permission-form>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import PermissionForm from '@/components/forms/PermissionForm.vue';

@Options({
    components: {
        Breadcrumb, PermissionForm
    },
    data(){
        return {
            data: {}
        }
    },
    mounted(){
        this.getRole(Number(this.$route.params.id));
    },
    methods: {
        getRole(id: number){
            this.$api.getRoleInfoById(id).then((res: any) => {
                console.log(res)
                this.data = res.data;
            })
        }
    }
})

export default class RoleEdit extends Vue {}
</script>