
import { Options, Vue } from 'vue-class-component';
import Breadcrumb from '@/components/layout/Breadcrumb.vue';
import PermissionForm from '@/components/forms/PermissionForm.vue';

@Options({
    components: {
        Breadcrumb, PermissionForm
    },
    data(){
        return {
            data: {}
        }
    },
    mounted(){
        this.getRole(Number(this.$route.params.id));
    },
    methods: {
        getRole(id: number){
            this.$api.getRoleInfoById(id).then((res: any) => {
                console.log(res)
                this.data = res.data;
            })
        }
    }
})

export default class RoleEdit extends Vue {}
